.container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.contentHeader {
	display: flex;
	position: fixed;
	top: 0;
	width: 100%;
	background: white;
	margin-top: 64px;
	z-index: 800;
}

.contentHeaderContainer {
	display: flex;
	background: white;
	padding: 20px 50px;
}

.filterOptions {
	display: grid;
	gap: 12px;
}

.filterButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButton img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(4458%)
		hue-rotate(175deg) brightness(102%) contrast(95%);
}

.filterButton:hover {
	cursor: pointer;
}

.filterButtonSelected {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #278ea5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButtonSelected:hover {
	cursor: pointer;
}

.filterButtonSelected img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(7482%)
		hue-rotate(322deg) brightness(106%) contrast(107%);
}

.graphContainerOrderCount {
	background: #ffffff;
	padding: 20px 20px;
	height: 440px;
}

.graphContainerOrderAmount {
	background: #ffffff;
	height: 440px;
	padding: 20px 20px;
}

.statusStatContainer {
	background: #ffffff;
	padding: 15px;
	cursor: pointer;
	transition: background 0.3s, border 0.3s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}

.statusStatContainer:hover {
	background: var(--primary-05);
}

.statByStatusValueContainer {
	font-size: 16px;
	color: black;
	font-weight: bold;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	height: 12px;
}

.pieAndCycleGraphContainer {
	background: #ffffff;
	height: 460px;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
}

.axisLabel {
	margin-top: auto;
	font-size: 12px;
	align-self: center;
	fill: rgb(51, 51, 51);
	color: black;
}

.content {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeTabContainer {
	display: flex;
	justify-content: flex-end;
}

.tabInOrderCycleAndPieContainer {
	display: flex;
}

.link {
	color: #278ea5;
}

.timeTabSelected {
	padding: 4px 16px;
	border: 1px solid #288ea5;
	background: #e3f3f6;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #288ea5;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.timeTab {
	padding: 4px 16px;
	border: 1px solid #ebebeb;
	border-radius: 48px;
	margin: 0 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #999999;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
}

.timeTab:hover {
	border: 1px solid #ebebeb;
	background: #e3f3f6;
	font-weight: bold;
	color: #288ea5;
	cursor: pointer;
	transition: background 0.2s, color 0.2s;
}

.tabContainer {
	display: flex;
}

.data {
	width: 100%;
    padding-top: 24px;
    padding-bottom: 32px;
}

.data h3 {
	color: black;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 24px;
}

.statContainer {
	padding: 24px;
	flex: 1;
	display: flex;
	flex-direction: column;
    background: #FAFAFA;
}

.statLeft {
	flex: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border: 0px solid #ebebeb;
	background-color: #f2f8fa;
}

.statRight {
	flex: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border: 0px solid #ebebeb;
	background-color: #f2fefb;
}

.dropdownButton {
	border: 1px solid #ebebeb;
	border-radius: 20px;
}

.stat h2 {
	font-size: 16px;
	color: #4b5766;
}

.statValueContainer {
	font-size: 20px;
	color: black;
	font-weight: bold;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.percentageContainer {
	display: flex;
	align-items: center;
}

.percentagePositive {
	color: #00ba88;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.percentageNegative {
	color: #ff5959;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.yesterday {
	font-size: 14px;
	color: #64748b;
	font-weight: normal;
	margin-left: 6px;
}

.dash {
	height: 2px;
	width: 7px;
	background-color: #278ea5;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
	width: 103px;
}

.section {
	margin-bottom: 40px;
}

.radioColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.image {
	position: relative;
	width: 100%;
	height: 240px;
	object-fit: cover;
}

.productsContainer {
	display: flex;
	overflow: hidden;
	width: 100%;
	border-radius: 10px;
}

.productItemContainer {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	width: 180px;
	margin: 0 32px;
}

.productItemContainer:first-of-type {
	margin-right: 32px;
	margin-left: 0;
}

.productItemContainer:last-of-type {
	margin-right: 0px;
	margin-left: 32px;
}

.productImageContainer {
	border-radius: 4px;
	overflow: hidden;
	width: 176px;
	height: 176px;
	outline: none;
}

.productImage {
	width: 176px;
	height: 176px;
	object-fit: contain;
}

.productName {
	margin-top: 16px;
	font-size: 16px;
	font-weight: bold;
	color: black;
}

.productWeight {
	margin-top: 4px;
	margin-bottom: 4px;
	color: #656565;
	font-size: 12px;
}

.productPrice {
	font-size: 14px;
	color: black;
}

.slideButton {
	width: 40px !important;
	height: 40px !important;
	position: absolute;
	top: 68px;
	padding: 0 !important;
	background: rgba(255, 255, 255, 0.6) !important;
	backdrop-filter: blur(10px);
}

.slideButtonLeft {
	left: 8px;
}

.slideButtonRight {
	right: 8px;
}

.productViewButton {
	cursor: pointer;
	margin-left: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.timeRange {
	cursor: pointer;
	margin-left: auto;
	margin-bottom: 10px;
}

.productGraphContainer {
	border-radius: 16px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	height: 384px;
}

.productItemSpinnerContainer {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	height: 176px;
}

.emptyGraph {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
	height: 100%;
}

.emptyProducts {
	height: 176px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.dateString {
	font-size: 14px;
	color: #999999;
}

.timeScheduleContainer {
	color: #999999;
	display: flex;
	padding-block: 9px;
	font-size: 12px;
	margin-bottom: 5px;
}

.separator {
	margin: 0 6px;
}

.statColumnLeft {
	border: 0px solid #ebebeb;
	border-radius: 16px;
}

.statColumnRight {
	border: 0px solid #ebebeb;
	border-radius: 16px;
}

.statColumn:first-of-type {
	margin-right: 0px;
}

.subTitle {
	display: flex;
	align-items: center;
	gap: 12px;
}

.tooltip {
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	background: white;
	padding: 4px 8px;
	border-radius: 4px;
}

.tooltipPayment {
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
	display: flex;
	align-items: center;
	gap: 7px;
	background: white;
	padding: 4px 8px;
	border-radius: 4px;
}

.orderCards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	padding-top: 48px;
}

.overlay {
	z-index: 999;
}

.discountChartAxisLabelContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

.expandButtonContainer {
	width: 100%;
	text-align: center;
	position: absolute;
	margin-top: 8px;
}

.topContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
    padding: 24px;
    border: solid 1px #EBEBEB;
    margin-bottom: 24px;

	> .masterFilter {
		display: flex;
	}
}

.pageTitle {
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    margin: 0;
}

.topTenCustomerContainerHead {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.topTenCustomerBtnContainer {
	display: flex;
	align-items: center;
    gap: 16px;
}

.dateRangeAndDatePickerContainer {
	display: flex;
	align-items: center;
}

.masterFilterDateContainer {
	display: flex;
	align-items: center;
}

.masterFilterText {
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	border: solid 1px #288ea5;
	border-right: none;
	padding-left: 10px;
	padding-right: 16px;
    transform: translate(8px, 0);
}

.divider {
    height: 1px;
    width: 100%;
    background: solid 1px #EBEBEB;
}