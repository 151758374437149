.container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.contentHeader {
	display: flex;
	position: fixed;
	top: 0;
	background: white;
	margin-top: 64px;
	width: 100%;
	z-index: 800;
}

.contentHeaderContainer {
	display: flex;
	background: white;
	padding: 20px 24px 20px 24px;
	margin-left: -24px;
}

.filterButton {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButton img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(4458%)
		hue-rotate(175deg) brightness(102%) contrast(95%);
}

.filterButton:hover {
	cursor: pointer;
}

.filterButtonSelected {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #278ea5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButtonSelected:hover {
	cursor: pointer;
}

.filterButtonSelected img {
	filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(7482%)
		hue-rotate(322deg) brightness(106%) contrast(107%);
}

.singleChartContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.singlePieChartContainer {
	display: flex;
	flex-direction: column;
	flex: 0;
}

.graphContainer {
	height: 440px;
	padding: 20px 20px;
	background: #ffffff;
	border: 1px solid var(--bg-50);
}

.pieAndCycleGraphContainer {
	height: 440px;
	padding: 20px 20px;
	background: #ffffff;
	border: 1px solid var(--bg-50);
}

.content {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeTabContainer {
	display: flex;
	justify-content: flex-end;
}

.tabInOrderCycleAndPieContainer {
	display: flex;
}

.timeTabSelected {
	padding: 4px 16px;
	border: 1px solid #e3f3f6;
	background: #e3f3f6;
	margin: 0 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #288ea5;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.timeTab {
	padding: 4px 16px;
	border: 1px solid #ebebeb;
	margin: 0 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #999999;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
}

.timeTab:hover {
	border: 1px solid #e3f3f6;
	background: #e3f3f6;
	font-weight: bold;
	color: #288ea5;
	cursor: pointer;
	transition: background 0.2s, color 0.2s;
}

.tabContainer {
	display: flex;
	gap: 40px;
}

.data {
	width: 100%;
	padding: 24px 0px;
}

.data h3 {
	color: black;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 24px;
}

.statContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
	border: 1px solid var(--bg-50);
}

.stat {
	flex: 1;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
}

.stat h2 {
	font-size: 16px;
	color: #4b5766;
}

.statValueContainer {
	font-size: 20px;
	color: black;
	font-weight: bold;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.percentageContainer {
	display: flex;
	align-items: center;
}

.percentagePositive {
	color: #00ba88;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.percentageNegative {
	color: #ff5959;
	font-weight: normal;
	font-size: 16px;
	margin-left: 6px;
}

.yesterday {
	font-size: 14px;
	color: #64748b;
	font-weight: normal;
	margin-left: 6px;
}

.dash {
	height: 2px;
	width: 7px;
	background-color: #278ea5;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
	width: 103px;
}

.section {
	margin-bottom: 40px;
}

.radioColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.overlay {
	z-index: 999;
}
