.statColumnLeft {
	border: 0px solid #ebebeb;
	border-radius: 16px;
}

.statColumnRight {
	border: 0px solid #ebebeb;
	border-radius: 16px;
}

.graphContainer {
	background: #ffffff;
	height: 460px;
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
}

.tabInGraphContainer {
	display: flex;
}

.subTitle {
	display: flex;
	align-items: center;
	gap: 12px;
}

.overlay {
	z-index: 999;
}

.timeTabSelected {
	padding: 4px 16px;
	border: 1px solid #288ea5;
	background: #e3f3f6;
	margin: 0 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #288ea5;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.dateString {
	font-size: 14px;
	color: #999999;
}

.separator {
	margin: 0 6px;
}

.dash {
	height: 2px;
	width: 7px;
	background-color: #278ea5;
}

.downloadButton {
	cursor: pointer;
	margin-left: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.tooltip {
	background: #ffffff;
	padding: 8px 12px;
	border-radius: 4px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.statContainer {
    padding: 24px;
	flex: 1;
	display: flex;
	flex-direction: column;
    background: #FAFAFA;
}